import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://pwa-cm-be.ita.belka-games.com/',
        prepareHeaders: (headers) => {
            return headers
        }
    }),
    endpoints: () => ({})
})

export default api
